/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {L} from "@src/style/mdxStyle.js";
import ReactCompareImage from "react-compare-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "ограничение-яркостных-масок",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BE%D0%B3%D1%80%D0%B0%D0%BD%D0%B8%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D1%8F%D1%80%D0%BA%D0%BE%D1%81%D1%82%D0%BD%D1%8B%D1%85-%D0%BC%D0%B0%D1%81%D0%BE%D0%BA",
    "aria-label": "ограничение яркостных масок permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ограничение яркостных масок"), "\n", React.createElement(_components.p, null, "Один из самых популярных приёмов при работе с яркостными масками среди пейзажистов это локальное ограничение маски с помощью групп."), "\n", React.createElement(_components.p, null, "С помощью яркостных масок можно строить очень сложные эффекты, но если вы хотите точно и локально применить полученный эффект, то можно обернуть его в группу и ограничить эффект с помощью маски группы."), "\n", React.createElement(_components.p, null, "Это совсем не трудно."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Создайте эффект, ограниченный яркостной маской"), "\n"), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[0].publicURL,
    rightImage: props.data.mdx.frontmatter.images[1].publicURL
  }), "\n", React.createElement(_components.p, null, "Тут я выбрал зеленые цвета с помощью маской и повысил их яркость. Основная цель - сделать тину в воде более яркость на фоне тёмной воды."), "\n", React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Оберните ваш эффект в группу с чёрной маской"), "\n"), "\n", React.createElement(_components.p, null, "Если вы пользуетесь ARCPanel для этого достаточно кликнуть на чёрную иконку группы. Для всех остальных - создайте новую группу, перенесите туда эффект, создайте маску у группы и инвертируйте её."), "\n", React.createElement(_components.ol, {
    start: "3"
  }, "\n", React.createElement(_components.li, null, "Рисуйте белой кистью по маске группы чтобы проявить эффект там, где нужно"), "\n"), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[1].publicURL,
    rightImage: props.data.mdx.frontmatter.images[2].publicURL
  }), "\n", React.createElement(_components.p, null, "Этот простой приём даёт вам ещё больше контроля на эффектами с яркостными масками. Насколько я знаю почти все профессиональные фотографы, которые используют яркостные маски, также пользуются этим приёмом."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[0].publicURL,
    rightImage: props.data.mdx.frontmatter.images[2].publicURL
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
